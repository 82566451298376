/* You can add global styles to this file, and also import other style files */
@import "./assets/sass/style";

// Replace above style with this SCSS file to enable dark mode styles
//@import "./assets/sass/style.dark";

@import "./assets/sass/style.angular.scss";
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import "~@ng-select/ng-select/themes/default.theme.css";
@import '~@angular/cdk/overlay-prebuilt.css';

#kt_body {

    background-color: #f5f8fa !important;
}

body {

    background-color: #f5f8fa !important;
}

.link-primary {
    color: #2b5f8f !important;
}

.aside-dark .menu .menu-item .menu-link.active {
    transition: color 0.2s ease, background-color 0.2s ease;
    /* background-color: #1b1b28; */
    background-color: #2b5f8f;
    color: #ffffff;
}

.aside-dark .menu .menu-item .menu-link .menu-icon {

    color: #2b5f8f;
}

.aside-dark .menu .menu-item .menu-link .menu-title {
    color: #2b5f8f;
}

.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) {

    background-color: #2b5f8f;
    color: #ffffff;
}


.aside-dark .menu .menu-item:not(.here) .menu-link:hover:not(.disabled):not(.active):not(.here) .mat-icon {

    background-color: #2b5f8f;
    color: #ffffff;
}


@media (min-width: 992px) {

    .aside .aside-menu {
        width: 265px;
        background-color: #ffffff;
    }

    .aside-fixed .aside {

        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
    }

    .header-fixed .header {

        background-color: #ffffff;
        height: 58px;
    }

    .aside-enabled.aside-fixed.header-fixed .header {
        left: 0px;
        box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
        z-index: 1000;
    }

    .aside-fixed .aside {
        position: fixed;
        top: 58px;

    }

    .header-fixed.toolbar-fixed .wrapper {
        padding-top: calc(0px + var(--kt-toolbar-height));
    }

    .aside-enabled.aside-fixed.header-fixed[data-kt-aside-minimize=on] .header {
        left: 0px;
        transition: left 0.3s ease;
    }

    .aside .aside-logo {

        height: 25px;
        justify-content: end !important;

    }
}

.aside.aside-dark .aside-logo {
    background-color: #ffffff;
    // padding-top: 1.25rem !important;
    padding-top: 1rem !important;
}

.basic-font-class {

    color: #2b5f8f;
}


.name-content-class {

    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}



@media (min-width: 992px) {

    .content {
        padding: 20px 0;
    }

    .container-xxl {
        padding: 0px 18px;
    }
}

.card .card-header {

    padding: 0 1.8rem;
}


@media (min-width: 1400px) {

    .container-xxl {
        max-width: 1442px;
    }
}


.btn.btn-primary {
    color: #FFFFFF;
    border-color: #2b5f8f;
    background-color: #2b5f8f;
}

.btn.btn-primary:hover:not(.btn-active) {

    color: #FFFFFF;
    border-color: #2b5f8fd4 !important;
    background-color: #2b5f8fd4 !important;
}


@media (max-width: 991.98px) {

    .header-tablet-and-mobile-fixed.toolbar-tablet-and-mobile-fixed .wrapper {
        padding-top: calc(0px + var(--kt-toolbar-height-tablet-and-mobile));
    }
}

.cursor-pointer {
    cursor: pointer !important;
}

.matSidebar-icon {

    color: #2b5f8f !important;
}


//menu dropdown list
.btn-dropdown-class {
    color: #2b5f8f !important;
}

.btn-icon-dropdown-class {

    color: #2b5f8f !important;
}

.mat-menu-item {

    height: 40px !important;
    color: #2b5f8f !important;
}

//


//For Fix the mat-autocomplete issue
.modal-backdrop.fade.in {
    z-index: 999 !important;
}

.modal {
    z-index: 1000 !important;
}

.modal-backdrop {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 990 !important;
}

//


.ngx-show-hide-password .btn-primary {

    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

@media (min-width: 992px) {

    .aside {
        width: 210px;
        // width: 265px;
        transition: width 0.3s ease;
    }
}

@media (min-width: 992px) {

    .aside-enabled.aside-fixed .wrapper {
        transition: padding-left 0.3s ease;
        // padding-left: 265px;
        padding-left: 210px;
    }
}

@media (min-width: 992px) {

    [data-kt-aside-minimize=on] .aside.aside-hoverable:hover:not(.animating) {
        transition: width 0.3s ease;
        width: 210px;
        box-shadow: 5px 0px 10px rgb(70 78 95 / 8%);
    }

}


.custom-sidebar-class {

    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
    margin-top: 6px !important;
}


.aside.aside-dark {
    background-color: #ffffff;
}

.align-items-center-class{

    align-items: center;
}


@media screen and (max-width: 767px) and (min-width: 200px) {
    .sidebar-btn-header-class {

        margin-bottom: 5px !important;
    }
    .card-title{

        display: block !important;
    }
    .card .card-header .card-toolbar {
        display: block !important;
    }
    .d-flex .align-items-stretch .justify-content-between{

        width: 100% !important;
    }
    .w-250px {
        width: 200px !important;
    }
    .paginate-dropdown-class{

        width: 200px !important;
    }
    .align-items-center-class{

        align-items: baseline !important;
    }
    .card .card-header {
        padding: 0 1rem !important;
    }
    .margin-right-class {
        margin-right: 0px !important;
    }
    .mar-right-class{

        margin-right: 8px !important;
    }
}

@media screen and (max-width: 700px) and (min-width: 400px) {
    .sidebar-btn-header-class {

        margin-bottom: 5px !important;
    }
    .card-title{

        display: block !important;
    }
    .card .card-header .card-toolbar {
        display: block !important;
    }
    .d-flex .align-items-stretch .justify-content-between{

        width: 100% !important;
    }
    .w-250px {
        width: 230px !important;
    }
    .paginate-dropdown-class{

        width: 230px !important;
    }
    .align-items-center-class{

        align-items: baseline !important;
    }
    .card .card-header {
        padding: 0 1rem !important;
    }
    .margin-right-class {
        margin-right: 0px !important;
    }
    .mar-right-class{

        margin-right: 8px !important;
    }
}

@media screen and (max-width: 800px) and (min-width: 767px) {
    .sidebar-btn-header-class {

        margin-bottom: 5px !important;
    }

    .card-title{

        display: block !important;
    }
    .card .card-header .card-toolbar {
        display: block !important;
    }

    .d-flex .align-items-stretch .justify-content-between{

        width: 100% !important;
    }

    .w-250px {
        width: 230px !important;
    }
    .paginate-dropdown-class{

        width: 230px !important;
    }
    .align-items-center-class{

        align-items: baseline !important;
    }
    .card .card-header {
        padding: 0 1rem !important;
    }

    .margin-right-class {
        margin-right: 0px !important;
    }
    .mar-right-class{

        margin-right: 8px !important;
    }
}


 .popover {
    width: 200% !important;
    border: 1px solid rgba(0,0,0,.2) !important;
  }
  
   .popover-header {
    padding: 0.5rem 0.75rem;
    margin-bottom: 0;
    // font-size: 1rem;
    background-color: #f7f7f7;
    border-bottom: 1px solid #ebebeb;
    border-top-left-radius: calc(0.3rem - 1px);
    border-top-right-radius: calc(0.3rem - 1px);
    font-weight: 600 !important;
    font-size: 14px !important;
  }

 .popover-body {
    padding: 0.5rem 0.75rem;
    color: #212529;
}

 .btn-outline-secondary {
    color: #181c32 !important;
    border-color: #E4E6EF !important;
    background: #E4E6EF !important;
}

 .btn-outline-secondary:hover {
    color: #181c32;
    border-color: #E4E6EF !important;
    background: #E4E6EF !important;
}

 .bs-popover-top {
    margin-bottom: 0.5rem !important;
}

 .btn.btn-danger:hover:not(.btn-active) {
    color: #FFFFFF;
    border-color: #507ca4 !important;
    background-color: #507ca4 !important;
}

 .btn.btn-danger {
    color: #FFFFFF;
    border-color: #2b5f8f !important;
    background-color: #2b5f8f !important;
}

 .default-class {
    color: #181c32 !important;
    border-color: #E4E6EF !important;
    background: #E4E6EF !important;
}

 .btn.btn-primary:active:not(.btn-active){

    color: #FFFFFF;
    border-color: #2b5f8f;
    background-color: #2b5f8f !important;
}


/////// custom-sidebar-class ////// 
.custom-sidebar-zindex-class{

    z-index: 99;
}

.drawer {
    display: flex !important;
    overflow: auto;
    z-index: 110;
    position: fixed;
    top: 57px;
    bottom: 0;
}
.menu-btn-padding-class{

    padding: 5px !important;
    margin-right: 8px;

}

.menu-icon-btn-class{

    line-height: 1.3 !important;
    color: #2b5f8f !important;
}

.drawer-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
    z-index: 109;
    background-color: unset !important;
    animation: animation-drawer-fade-in 0.3s ease-in-out 1;
}

.drawer {
    display: flex !important;
    overflow: hidden !important;
}


.mat-tab-label.mat-tab-label-active{

    color: #2b5f8f !important;
opacity: 5 !important;
}

.mat-tab-label-active{

    color: #2b5f8f !important;
    opacity: 5 !important;
}

.drawer-nav {
    top: 60px !important;
}

//autocomplete field css overwrite
.custom-mat-form-field .mat-input-element {
     caret-color: #5E6278!important;
}
.custom-mat-form-field.mat-form-field-invalid .mat-input-element {
    caret-color: #5E6278!important;
}
.custom-mat-form-field.mat-form-field.mat-form-field-invalid .mat-form-field-ripple{
background-color:transparent !important;
}
.custom-mat-form-field.mat-form-field input.mat-input-element {
    margin-top: -0.489em!important;
}
.custom-mat-form-field.mat-form-field .mat-form-field-appearance-legacy .mat-form-field-label{
    top: 1em;
}
.custom-mat-form-field.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #5E6278;
}
.custom-mat-form-field.mat-form-field.mat-form-field-invalid .mat-form-field-label {
    color: #5E6278;
}

.custom-mat-form-field.mat-form-field .mat-form-field-label {
    position: static;
  }
  .custom-mat-form-field.mat-form-field .mat-form-field-infix {
    margin-top: -7px !important;
}
.custom-mat-form-field.mat-form-field .mat-form-field-wrapper .mat-form-field-infix .mat-form-field-label-wrapper {
    padding-top: 1.34375em;
}
:host ::ng-deep .custom-mat-form-field.mat-form-field .mat-form-field-infix {
    margin-top: -6px !important;
}
  .custom-mat-form-field.mat-form-field.mat-focused .mat-form-field-ripple {
    background-color: transparent !important;
  }
  .custom-mat-form-field {
    border: none;
    outline: none;
    box-shadow: none;
  }
  .custom-mat-form-field.mat-form-field.mat-form-field-underline {
    background-color: transparent;
}

app-dashboard-visualization {
    .custom-mat-form-field.mat-form-field.mat-focused {
      .mat-form-field-ripple {
        background-color: transparent!important;
      }
    }
    .custom-mat-form-field .mat-form-field-infix {
      border-bottom: none!important;
      
    }
  }
  
  .custom-mat-form-field.mat-form-field-appearance-legacy .mat-form-field-underline {
     
      background-color: transparent!important;
  }
  
  .custom-mat-form-field.mat-form-field.mat-focused {
      .mat-form-field-infix {
        border-bottom: 0px!important;;
     
    }
  }
//autocomplete field css overwrite end
  